@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  *,
  html,
  body {
    font-family: "Helvetica Neue", Helvetica, Arial, Roboto, "Droid Sans",
      "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "ヒラギノ角ゴ ProN W3",
      "Hiragino Kaku Gothic ProN", sans-serif;
  }
  *:focus,
  *:focus-visible {
    outline: 0;
  }
}
